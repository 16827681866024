.site-nav {
    float: right;
    line-height: 56px;

    .menu,
    .menu-item {
        margin: 0;
        padding: 0;
        display: inline-block;
    }

    .menu-icon,
    .menu-toggle {
        display: none;
    }

    .menu-item {
        line-height: $base-line-height;
        margin-left: 10px;
        padding: 3px 6px;
        border: thin solid var(--dark-brand-color);
        border-radius: 5px;
        list-style-type: none;
        background-color: var(--brand-brand-color);
        &:hover {
            background-color: var(--light-brand-color);

            .page-link {
                color: var(--base-text-color);
            }
        }
    }

    .page-link {
        color: var(--base-bg-color);
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: calc($spacing-unit / 2);
        background-color: var(--light-bg-color);
        border: 1px solid var(--dark-bg-color);
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 30px;
            line-height: 0px;
            padding-top: 6px;
            font-size: 24px;
            text-align: center;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .menu {
            clear: both;
            display: none;
            padding-bottom: 5px;
        }

        .menu-toggle:checked + .menu {
            display: block;
        }

        .menu-item {
            background-color: unset;
            border: 0;
            border-radius: 0;
            display: block;
            padding: 7px 10px 7px 30px;
            margin: 0;
        }

        .page-link {
            color: var(--base-brand-color);
        }
    }
}
