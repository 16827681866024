@charset "utf-8";

//
// Base SCSS Variables
//

// fonts
$base-font-family: "Avenir Next", sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$fixed-font-family: "Andale Mono", monospace;
$fixed-font-size:   $base-font-size * 0.875;

$spacing-unit:     $base-font-size;

// Font Awesome

$fa-font-path: "../webfonts";
@import "font-awesome/fontawesome.scss";
@import "font-awesome/solid.scss";
@import "font-awesome/brands.scss";

// colors

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "color-schemes";
@import "layout";
