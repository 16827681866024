/**
 * Page content
 */

.page-heading {
    font-size: 20px;
}

.post-list,
.post-title-list,
.tag-list,
.year-list {
    @extend %vertical-rhythm;
    margin-left: 0px;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.month-list,
{
    @extend %vertical-rhythm;
    list-style: none;

    > li {
        margin-bottom: none;
    }
}

%contentframe {
    background-color: var(--light-bg-color);
    padding: $spacing-unit $spacing-unit 0;
    border: 1px solid var(--dark-bg-color);
    border-radius: $spacing-unit;
    box-shadow: 1px 1px $spacing-unit var(--dark-bg-color);

    @include media-query($on-palm) {
        padding: calc($spacing-unit / 2) calc($spacing-unit / 2) 0;
        border-radius: calc($spacing-unit / 2);
        box-shadow: none;
    }
}

.post {
    @extend %vertical-rhythm;
    .post-content {
        @extend %contentframe;
    }
}

.post-item,
.tag-item {
    @extend %list-rhythm;
    @extend %contentframe;
}

.post-meta {
    flex: none;
    clear: both;
    font-size: $small-font-size;
    color: var(--fade-text-color);

    blockquote { margin: calc($spacing-unit / 2) $spacing-unit; }
}

.post-link {
    flex: 1;
    /*font-size: 24px;*/
    margin: 0px;
}

.post-excerpt {
    @extend %clearfix;
    @extend %vertical-rhythm;
}

.tag-meta {
    @extend %vertical-rhythm;
    margin-left: $spacing-unit;
    font-size: $small-font-size;
    color: var(--fade-text-color);
}

/**
 * Posts
 */
.post-header {
    @extend %vertical-rhythm;
}

.post-title {
    font-size: calc($base-font-size * 21 / 8);
    letter-spacing: -1px;
    line-height: 1;
}

.post-content {
    @extend %clearfix;

    h1 { display: none; }

    h2 { font-size: calc($base-font-size * 13 / 8); }
    h3 { font-size: calc($base-font-size * 11 / 8); }
    h4 { font-size: calc($base-font-size * 11 / 8); }
    h5 { font-size: calc($base-font-size *  9 / 8); }
    h6 { font-size: calc($base-font-size *  9 / 8); }

    h2, h3, h4, h5, h6 { color: var(--fade-text-color); }

    h2 { border-top: thin solid var(--fade-text-color); }
    h2, h3, h5 { border-bottom: thin solid var(--fade-text-color); }

    blockquote {
        border-left: 2px solid var(--fade-text-color);
    }

    .clear {
        clear: both;
    }

    .right {
        clear: right;
        float: right;
        margin-right: 0px;
    }

    .left {
        clear: left;
        float: left;
        margin-left: 0px;
    }

    .max25 { max-width: 25%; }
    .max50 { max-width: 50%; }

    @include media-query($on-palm) {
        .left, .right {
            float: none;
            display: block;
            max-width: 100%;
        }
        .max25 { max-width: 25%; }
        .left.max25 { float: left; display: inline; }
        .right.max25 { float: right; display: inline; }
    }

    .spoiler {
        color: var(--base-bg-color);
        font-style: unset;
        background-color: var(--base-bg-color);
    }

    .nowrap {
        white-space: nowrap;
    }
}

.footnotes {
    border-top: thin solid var(--base-brand-color);
    padding-top: calc($spacing-unit / 4);
    color: var(--fade-text-color);
    margin-top: $spacing-unit;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;

    min-width: 100%;
    margin-bottom: $spacing-unit;

    .menu-item {
        border: thin solid var(--dark-bg-color);
        background-color: var(--light-bg-color);
        border-radius: 5px;
        padding: 3px 5px;
        margin: 0 5px;
        flex-basis: 3em;

        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .menu-item.focus,
    .menu-item:hover {
        background-color: var(--light-bg-color);
        color: var(--text-color);
    }
}

@import "syntax-highlighting";
