@import "base";

/**
 * Site header
 */
.site-header {
    background: var(--base-brand-color);
    min-height: 56px;
    padding: 0;

    // Positioning context for the mobile navigation icon
    position: relative;
    z-index: 1;
}

.site-title {
    color: var(--base-bg-color);
    font-size: 26px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
}

@import "site-menu";

/**
 * Page content
 */
.page-content {
    background-color: var(--base-bg-color);
    padding: $spacing-unit 0 0;
    flex-grow: 1;
    z-index: 0;
}

@import "layout-content";

/**
 * Site footer
 */
.site-footer {
    background: var(--base-bg-color);
    border-top: 2px solid var(--base-brand-color);
    padding: calc($spacing-unit / 2) 0;
    z-index: 0;
}
@include media-query($on-palm) {
    .site-footer {
        padding: 0;
    }
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit;
}

.contact-list,
.social-media-list {
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
}

.footer-col-wrapper {
    @extend %clearfix;
    color: var(--dark-text-color);
    display: flex;
    flex-flow: row wrap;
    align-items: first baseline;
    margin-left: calc($spacing-unit / -2);
    margin-right: calc($spacing-unit / -2);
}

.footer-col {
    font-size: $small-font-size;
    padding: calc($spacing-unit / 2);
}

.footer-col-1,
.footer-col-2 {
    flex: 1;
}

.footer-col-3 {
    flex: none;
}
